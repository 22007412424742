import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs/internal/Subscription';
import { AuthenticationService } from './authentication.service';
import { UserService } from './user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, Location } from '@angular/common';
import { take } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

const URL_MAPPING = {
  PRIVACY_POLICY: { en: '/en/privacy-policy', vi: '/chinh-sach-bao-mat' },
  CONTACT_US: { en: '/en/contact-us', vi: '/lien-he' },
  FAQ: { en: '/en/faq', vi: '/faq' },
  TERMS: { en: '/en/terms', vi: '/dieu-kien' },
  PLANS_ADDONS: { en: '/en/plans-addons', vi: '/plans-et-ajouts' },
  APARTMENT_RENTAL: { en: '/en/apartment-rental', vi: '/cho-thue-can-ho' },
  SETTINGS: { en: '/en/manage/settings', vi: '/manage/setting' },
  LIST_POST: { en: '/en/manage/list', vi: '/manage/list' },
  REVIEW_ADS: { en: '/en/manage/review-ads', vi: '/manage/review-ads' },
  LIST_USERS: { en: '/en/manage/list-users', vi: '/manage/list-users' },
  LISTS: { en: '/en/apartment-list', vi: '/thue-can-ho' },
  BASE_URL: { en: '/en', vi: '/' },
  DETAIL_ADS: { en: '/en/chitiet', vi: '/chitiet' },
};

@Injectable({ providedIn: 'root' })
export class UrlService {
  private static url: string;
  private static switchLang: boolean;
  private unsubscribe: Subscription[] = [];
  public urlLang = '';

  public privacyPolicy = URL_MAPPING.PRIVACY_POLICY.en;
  public contactUs = URL_MAPPING.CONTACT_US.en;
  public faq = URL_MAPPING.FAQ.en;
  public terms = URL_MAPPING.TERMS.en;
  public plansAddons = URL_MAPPING.PLANS_ADDONS.en;
  public apartmentRental = URL_MAPPING.APARTMENT_RENTAL.en;
  public baseUrl = URL_MAPPING.BASE_URL.en;
  public settings = URL_MAPPING.SETTINGS.en;
  public listPost = URL_MAPPING.LIST_POST.en;
  public reviewAds = URL_MAPPING.REVIEW_ADS.en;
  public listUsers = URL_MAPPING.LIST_USERS.en;
  public lists = URL_MAPPING.LISTS.en;
  public detailAds = URL_MAPPING.DETAIL_ADS.en;
  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document
  ) { }

  SetLang(language: string) {
    this.unsubscribe.push(
      this.translateService.use(language).subscribe({
        next: () => {
          const lang = this.translateService.currentLang;
          if (!lang) return;

          // Update URL mappings based on language
          Object.keys(URL_MAPPING).forEach((key) => {
            this[key.toLowerCase()] = URL_MAPPING[key][lang];
          });

          this.urlLang = lang === 'en' ? '/en' : '';
          const newUrl = this.mapUrlToLang(UrlService.url, lang);

          if (UrlService.switchLang && newUrl !== UrlService.url) {
            this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
              this.router.navigate([newUrl], { queryParams: params });
            });
            UrlService.switchLang = false;
          }

          if (isPlatformBrowser(this.platformId)) {
            this.cookieService.set(
              '.AspNetCore.Culture',
              `c=${lang}|uic=${lang}`,
              {
                expires: 7,
                path: '/',
                secure: true,
                sameSite: 'Strict'
              }
            );
          }

          if (this.authenticationService.getJwt()) {
            this.userService.setLanguage(lang).subscribe();
          }
        },
        complete: () => {
          this.unsubscribe.forEach((sub) => sub.unsubscribe());
          this.unsubscribe = [];
        }
      })
    );
  }

  private mapUrlToLang(currentUrl: string, lang: string): string {
    for (const [key, value] of Object.entries(URL_MAPPING)) {
      if (currentUrl.startsWith(value.en) || currentUrl.startsWith(value.vi)) {
        return lang === 'en' ? value.en : value.vi;
      }
    }
    return URL_MAPPING.BASE_URL[lang];
  }

  switchLang(lang: string = null) {
    const language =
      lang || (this.translateService.currentLang === 'vi' ? 'en' : 'vi');
    UrlService.url = this.location.path();
    UrlService.switchLang = true;
    this.SetLang(language);

    if (isPlatformBrowser(this.platformId)) {
      this.document.documentElement.lang = language;
    }
  }

  setLangLoad(lang: string) {
    UrlService.url = this.location.path();
    UrlService.switchLang = true;
    this.SetLang(lang);
  }
}
